import * as React from 'react'
import { styled } from '@material-ui/core/styles';
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import { dimensions, colors, breakpoints } from '../styles/variables'
import Container from './Container'

const sm = `@media (min-width: ${breakpoints.sm}px)`
const md = `@media (min-width: ${breakpoints.md}px)`
const lg = `@media (min-width: ${breakpoints.lg}px)`


const StyledFooter = styled('footer')({
  padding: `50px ${dimensions.containerPadding}rem`,
  color: colors.white,
  backgroundColor: colors.black,
})

const FooterInner = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',

  [md]: {
    flexDirection: 'row',
  }
})
const FooterMenu = styled('div')({
  display: 'flex',
  flex: '1',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  flexWrap: 'wrap',
  [lg]: {
    flexWrap: 'nowrap',
  }
})
const FooterSubmenu = styled('div')({
  flex: '1',
  flexDirection: 'column',
  textAlign: 'center',
  alignSelf: 'flex-start',
  minWidth: '50%',
  marginBottom: "2rem",

  [lg]: {
    minWidth: "0%"
  }
})

const HomepageLink = styled(Link)({
  color: colors.white,
  fontSize: '1.5rem',
  fontWeight: '600',

  '&:hover, &:focus': {
    textDecoration: 'none',
  }
})

interface FooterProps {
  title: string
}


const MenuHeader = styled(Link)({
  color: colors.white,
  fontSize: '1.0rem',
  fontWeight: '400',
  transition: 'all 0.1s',
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
  padding: "14px 10px",

  '&:hover, &:focus': {
    textDecoration: 'none',
    color: colors.intalexGreen,
  }
})

const MenuItem = styled('li')({
  margin: "12px 0",
  listStyleType: 'none',
})

const MenuLink = styled(Link)({
  color: colors.white,
  fontSize: '0.9rem',
  fontWeight: '400',
  transition: 'all 0.1s',
  whiteSpace: 'nowrap',
  padding: "15px 10px",

  '&:hover, &:focus': {
    textDecoration: 'none',
    color: colors.intalexGreen,
  }
})

const ExternalLink = styled('a')({
  color: colors.white,
  fontSize: '0.9rem',
  fontWeight: '400',
  transition: 'all 0.1s',
  whiteSpace: 'nowrap',
  padding: "15px 10px",

  '&:hover, &:focus': {
    textDecoration: 'none',
    color: colors.intalexGreen,
  }
})

const SocialLink = styled('a')({
  color: colors.white,
  fontSize: '0.9rem',
  fontWeight: '400',
  lineHeight: '1.5rem',
  transition: 'all 0.1s',
  whiteSpace: 'nowrap',
  width: '48px',
  overflow: 'hidden',
  height: '48px',
  display: 'inline-block',
  margin: '0 0.2rem',
  border: 'none',
  backgroundColor: colors.black,
  borderRadius: "5px",

  '&:hover, &:focus': {
    textDecoration: 'none',
    color: colors.white,
    backgroundColor: colors.intalexGreen,
  }
})

const Footer: React.FC<FooterProps> = ({ title }) => (
  <StyledFooter>
    <FooterInner>
      <Container>
        <HomepageLink to="/" title="Software Solutions by Intalex">
          <StaticImage src="../images/logo.svg"
            alt="Software Solutions by Intalex"
            placeholder="none"
            layout="fixed"
            height={26}
          />
        </HomepageLink>
        <p>High <span css={{ fontStyle: "italic", fontWeight: 600, color: colors.lightGreen }}>Performance</span> Software</p>

        <Container css={{ margin: "2rem 0" }}>
          <SocialLink as="a" href="https://www.facebook.com/IntalexTechnologies/" rel="noreferrer" target="_blank" title="Facebook"><FacebookIcon style={{ fontSize: "2.9rem", margin: "1px 0px 0px 1px" }} /></SocialLink>
          <SocialLink as="a" href="https://www.linkedin.com/company/intalex-technologies" rel="noreferrer" target="_blank" title="LinkedIn"><LinkedInIcon style={{ fontSize: "2.9rem", margin: "1px 0px 0px 1px" }} /></SocialLink>
          <SocialLink as="a" href="https://twitter.com/IntalexTech" rel="noreferrer" target="_blank" title="Twitter"><TwitterIcon style={{ fontSize: "2.9rem", margin: "1px 0px 0px 1px" }} /></SocialLink>
          <SocialLink as="a" href="https://www.youtube.com/channel/UCvRJkl5UWIaotc859PNeNMA" rel="noreferrer" target="_blank" title="YouTube"><YouTubeIcon style={{ fontSize: "2.9rem", margin: "1px 0px 0px 1px" }} /></SocialLink>
          <SocialLink as="a" href="https://www.instagram.com/intalextechnologies/" rel="noreferrer" target="_blank" title="Instagram"><InstagramIcon style={{ fontSize: "2.9rem", margin: "1px 0px 0px 1px" }} /></SocialLink>
        </Container>
      </Container>


      <FooterMenu>
        <FooterSubmenu>
          <MenuHeader to="/about-us/">
            About Us
          </MenuHeader>

          <ul css={{ listStyle: "none", padding: 0 }}>
            <MenuItem><MenuLink to="/about-us/team/">Meet The Team</MenuLink></MenuItem>
          </ul>
        </FooterSubmenu>

        <FooterSubmenu>
          <MenuHeader to="/products/">
            Products
          </MenuHeader>

          <ul css={{ listStyle: "none", padding: 0 }}>
            <MenuItem><MenuLink to="/business-platform/">Platform</MenuLink></MenuItem>
            <MenuItem><MenuLink to="/products/apps/eddy/">Eddy</MenuLink></MenuItem>
          </ul>
        </FooterSubmenu>
        <FooterSubmenu>
          <MenuHeader to="/services/">
            Services
          </MenuHeader>

          <ul css={{ listStyle: "none", padding: 0 }}>
            {/*<MenuItem><MenuLink to="/hosting/">Website Hosting</MenuLink></MenuItem>*/}
            <MenuItem><MenuLink to="/websites/">Web Development</MenuLink></MenuItem>
            <MenuItem><MenuLink to="/software/">Software/App Development</MenuLink></MenuItem>
            <MenuItem><MenuLink to="/services/microservices/">Microservices</MenuLink></MenuItem>
            <MenuItem><MenuLink to="/optimised/">Optimised Websites</MenuLink></MenuItem>
          </ul>
        </FooterSubmenu>
        <FooterSubmenu>
          <MenuHeader to="/contact/">
            Support
          </MenuHeader>

          <ul css={{ listStyle: "none", padding: 0 }}>
            <MenuItem><MenuLink to="/docs/">Help Portal</MenuLink></MenuItem>
            <MenuItem><MenuLink to="/contact/">Contact Us</MenuLink></MenuItem>
          </ul>
        </FooterSubmenu>


      </FooterMenu>

    </FooterInner>
  </StyledFooter>
)

export default Footer
