import * as React from 'react'
import { styled } from '@material-ui/core/styles';

const StyledPage = styled('div')({
  display: 'block',
  flex: 1,
  position: 'relative',
  //marginBottom: '3rem',
})

interface PageProps {
  className?: string
}

const Page: React.FC<PageProps> = ({ children, className, ...rest }) => <StyledPage className={className} {...rest}>{children}</StyledPage>

export default Page
