import * as React from 'react'
import { styled, makeStyles } from '@material-ui/core/styles'
import { transparentize } from 'polished'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { heights, dimensions, colors, breakpoints } from '../styles/variables'
import Container from './Container'

const sm = `@media (min-width: ${breakpoints.sm}px)`
const md = `@media (min-width: ${breakpoints.md}px)`
const lg = `@media (min-width: ${breakpoints.lg}px)`
const xl = `@media (min-width: ${breakpoints.xl}px)`

const StyledHeader = styled('header')({
  height: `${heights.header}px`,
  padding: `0 0.9rem`,
  color: `${transparentize(0.5, colors.white)}`,
  backgroundColor: colors.black,

  [md]: {
    padding: `0 ${dimensions.containerPadding}rem`,
  }
})

const HeaderInner = styled(Container)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',

  [sm]: {
    justifyContent: 'center',
  }
})

const HomepageLink = styled(Link)({
  color: colors.white,
  fontSize: '1.5rem',
  fontWeight: '600',

  '&:hover, &:focus': {
    textDecoration: 'none',
  }
})

const Button = styled('a')({
  color: colors.white,
  fontSize: '1.0rem',
  fontWeight: '400',
  position: 'absolute',
  top: '20px',
  right: 0,
  marginRight: '15px',
  padding: '8px 30px',
  border: `1px solid ${colors.white}`,
  transition: 'all 0.1s',
  backgroundColor: colors.black,

  '&:hover, &:focus': {
    textDecoration: 'none',
    backgroundColor: colors.intalexGreen,
    cursor: 'pointer',
  },
  [sm]: {
    padding: '8px 45px',
  },
  [md]: {
    marginRight: '30px',
    padding: '8px 60px',
  }
})


const MenuLink = styled(Link)({
  color: colors.white,
  fontSize: '0.9rem',
  fontWeight: '400',
  transition: 'all 0.1s',
  whiteSpace: 'nowrap',
  margin: '0.5rem',

  '&:hover, &:focus': {
    textDecoration: 'none',
    color: colors.intalexGreen,
  },
  [md]: {
    margin: '1rem',
  },
  [lg]: {
    margin: '2rem',
  }
})

const styles = makeStyles((theme) => ({
  logo: {
    position: 'absolute !important' as 'absolute',
    top: "15px",
    left: "5px",
    maxWidth: "150px",

    [sm]: {
      top: "30px",
      maxWidth: "150px",
    },
    [md]: {
      top: "25px",
      maxWidth: "208px",
    }
  },
  menuContainer: {
    paddingTop: "25px",

    [sm]: {
      paddingTop: 0,
    },
  },
}))


interface HeaderProps {
  title: string
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const classes = styles();
  return (
    <StyledHeader>
      <HeaderInner>
        <HomepageLink to="/" title="Software Solutions by Intalex">
          <StaticImage src="../images/logo.svg"
            alt="Software Solutions by Intalex"
            placeholder="none"
            //layout="fixed"
            className={classes.logo}
          />
        </HomepageLink>
        <div className={classes.menuContainer}>
          <MenuLink as="a" to="/business-platform/">Platform</MenuLink>
          <MenuLink as="a" to="/software/">Software</MenuLink>
          <MenuLink as="a" to="/websites/">Websites</MenuLink>
        </div>
        <Button
          as="a"
          href="https://business.intalex.com.au/"
          target="_blank"
          rel="noopener"
        >
          Login
        </Button>
      </HeaderInner>
    </StyledHeader>
  )
}

export default Header
