import * as React from 'react'
import { styled} from '@material-ui/core/styles';

const StyledLayoutRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
})

interface LayoutRootProps {
  className?: string
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => (
  <>
    <StyledLayoutRoot className={className}>
        {children}
    </StyledLayoutRoot>
  </>
)

export default LayoutRoot
