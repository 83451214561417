import * as React from 'react'
import { styled } from '@material-ui/core/styles'

//import { widths } from '../styles/variables'
//import { getEmSize } from '../styles/mixins'

const StyledContainer = styled('div')({
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: 'auto',
})

interface ContainerProps {
  className?: string
}

const Container: React.FC<ContainerProps> = ({ children, className, ...rest }) => <StyledContainer className={className} { ...rest }>{children}</StyledContainer>

export default Container
